import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Button from "../components/UI/Button";
import useBoolean from "../hooks/useBoolean";
import OurTeam from "../components/TrainingPage/OurTeam";
import person1 from "../images/about/team/person-1.png";
import person2 from "../images/about/team/person-2.png";
import person3 from "../images/about/team/person-3.png";
import person4 from "../images/about/team/person-4.png";

const About: React.FC = () => {
  const [isHoverBtn, setHoverBtn] = useBoolean();

  return (
    <DefaultLayout>
      <SEO
        title="About Us | Ensemble"
        url="https://ensembleai.io/about"
        ogImage="https://ensembleai.io/assets/img/home.png"
      />
      <div className="lg:pt-[134px] lg:pb-[204px] pb-10 pt-[96px] rounded-bl-[45px] rounded-br-[45px] xl:rounded-br-[100px] xl:rounded-bl-[100px] px-6 bg-gradient-to-r from-[#40b7de] to-[#1976e0]">
        <div className="flex justify-center max-w-[1140px] mx-auto w-full">
          <div className="space-y-2">
            <h1 className="lg:text-[34px] text-[34px] font-bold text-white text-center">
              About Us
            </h1>
            <p className="text-white text-lg font-semibold text-center">
               Experts In Cloud Based Analytics and AI
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-[1188px] w-full mx-auto relative lg:top-[-142px] px-6 pt-10 lg:py-0 lg:mb-[-142px]">
        <StaticImage
          src="../images/about/about-hero.jpg"
          imgClassName="rounded-xl"
          sizes="(max-width: 576px) 100vw, (max-width: 1024px) 75vw, 1200px"
          width={1140}
          height={420}
          objectFit="contain"
          alt="about hero"
        />
      </div>

      <div className="max-w-[1188px] p-6 lg:pb-20 lg:pt-16 mx-auto">

  <div className="flex lg:gap-16 lg:flex-row flex-col gap-6">
    <div className="flex max-w-none lg:max-w-[460px] w-full">
      <h2 className="lg:text-[32px] text-[28px] font-bold md:text-4xl">
        <span className="gradient-blue-text">We Help Businesses Deploy Advanced Analytics And AI Enabled Solutions</span>
      </h2>
    </div>
    <div className="w-full space-y-6 text-black">
      <p>We are an engineering led consultancy that help our clients deploy advanced analytics and intelligent AI enabled tools, platforms and applications.</p>
      <p>The systems we deploy unlock the insights that drive growth, increase efficiency, enhance customer experience and fight threats including financial crime and regulatory compliance.</p>
    </div>
  </div>
  </div>


  <div className="max-w-[1188px] p-6 lg:pb-20 lg:pt-16 mx-auto">
        <div className="flex lg:gap-16 lg:flex-row flex-col gap-6">
          <div className="flex max-w-none lg:max-w-[460px] w-full">
            <h2 className="lg:text-[32px] text-[28px] font-bold md:text-4xl">
              <span className="gradient-blue-text">Expertise In Disruptive Cloud Enabled Innovation</span>
            </h2>
          </div>
          <div className="w-full space-y-6 text-black">
            <p>Ensemble AI was founded by <a href="https://www.linkedin.com/in/pontusnoren/">Pontus Noren</a> and <a href="https://www.linkedin.com/in/benjaminwootton/?originalSubdomain=uk">Benjamin Wootton</a>, previously founders of hugely successful-born-in-the-cloud AWS Premier Partners Cloudreach (2009) and Contino (2014).</p>
            <p>These two businesses grew to become two of the most successful AWS / cloud “boutique” partners in Europe, US and Australia with a combined concurrent workforce of 1,200+ people, serving over 500 enterprise clients including the likes of BP, Allianz, Barclays, ADP, NatWest, Aviva, Volkswagen, EDF, Equifax, News Corp, Financial Conduct Authority, Fitch Ratings, Kempinski Hotel Group, Lloyds, Direct Line and many others.</p>
            <p>Drawing on our experiences and leadership from the early days of the cloud transformation era, we are now empowering our clients to harness the next major disruption in enterprise technology - AI.</p>
          </div>
        </div>
      </div>
  
  <div className="max-w-[1188px] p-6 lg:pb-20 lg:pt-16 mx-auto">
        <div className="flex lg:gap-16 lg:flex-row flex-col gap-6">
          <div className="flex max-w-none lg:max-w-[460px] w-full">
            <h2 className="lg:text-[32px] text-[28px] font-bold md:text-4xl">
              <span className="gradient-blue-text">A Simple and Collaborative Engagement Model</span>
            </h2>
          </div>
          <div className="w-full space-y-6 text-black">
            <p>We partner with our customers to build and optionally manage their cloud based analytics systems, including rapid environment setup, data integration, and the development of bespoke dashboards, analytics and machine learning models.</p>
            <p>Our services incorporate data engineering, data science and software engineering in order to deploy end to end analytics systems.</p>
          <p>We work with both large regulated enterprise and medium sized scaleups, who we help to quickly deploy powerful new capabilities whilst building a technology platform that supports their future ambitions.</p>
          <p>We aim to be easy, agile and pragmatic to work with and deliver win-win partnerships with our clients.</p>
          </div>
        </div>
      </div>


      <div className="max-w-[1188px] p-6 lg:pb-20 lg:pt-16 mx-auto">
        <div className="flex lg:gap-16 lg:flex-row flex-col gap-6">
          <div className="flex max-w-none lg:max-w-[460px] w-full">
            <h2 className="lg:text-[32px] text-[28px] font-bold md:text-4xl">
              <span className="gradient-blue-text">Deep Expertise In Cloud Native Analytics Platforms</span>
            </h2>
          </div>
          <div className="w-full space-y-6 text-black">
            <p>We are engineering led, and bring deep expertise in building advanced analytics and AI powered solutions using the best that the hyperscale cloud providers have to offer.</p>
          </div>
        </div>
      </div>

      <div className="w-full bg-brown-500">
        <div className="max-w-[1188px] p-6 lg:pt-20 lg:pb-[225px] mx-auto">
          <div className="px-4">
            <h2 className="lg:text-[32px] text-[28px] text-center font-bold mb-4">
              End To End Cloud Data <span className="underimage">Solutions</span>
            </h2>
            <p className="text-grey-400 text-center mx-auto mb-8 lg:mb-10">
              We integrate cloud based modern data stack tools to deliver end-to-end solutions.
            </p>
          </div>
          <div className="flex gap-2 lg:gap-6 justify-between flex-col md:flex-row items-center md:items-start flex-nowrap md:flex-wrap lg:flex-nowrap">
          <div className="h-[112px] lg:h-28 w-full flex items-center justify-center bg-white rounded-[10px] shadow-4xl">
              <StaticImage
                className="max-w-[96px]"
                src="../images/about/logos/aws.png"
                alt="aws"
                objectFit="contain"
              />
            </div>
          <div className="h-[112px] lg:h-28 w-full flex items-center justify-center bg-white rounded-[10px] shadow-4xl">
              <StaticImage
                className="max-w-[148.8px]"
                src="../images/about/logos/clickhouse.png"
                alt="clickhouse"
                objectFit="contain"
              />
            </div>
            <div className="h-[112px] lg:h-28 w-full flex items-center justify-center bg-white rounded-[10px] shadow-4xl">
              <StaticImage
                className="max-w-[120px]"
                src="../images/about/logos/dbt.png"
                alt="dbt"
                objectFit="contain"
              />
            </div>
            <div className="h-[112px] lg:h-28 w-full flex items-center justify-center bg-white rounded-[10px] shadow-4xl">
              <StaticImage
                className="max-w-[118.4px]"
                src="../images/about/logos/kafka.png"
                alt="kafka"
                objectFit="contain"
              />
            </div>

           
            <div className="h-[112px] lg:h-28 w-full flex items-center justify-center bg-white rounded-[10px] shadow-4xl">
              <StaticImage
                className="max-w-[144.32px]"
                src="../images/about/logos/azure.webp"
                alt="azure"
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-6 lg:top-[-160px] px-6 py-10 mx-6 xl:m-auto md:p-10 max-w-[1140px] from-red-600 to-pink-600 bg-gradient-to-r rounded-xl text-white flex justify-center items-center">
        <div className="space-y-4 max-w-[700px]">
          <h4 className="font-semibold text-center text-xl">
            Let's Start The Conversation!
          </h4>
          <p className="text-center text-sm">
          Work with us to develop your AI strategy, identifying an actionable roadmap to improve your data science, machine learning and AI capabilities.
          </p>
          <div className="flex justify-center">
            <Button
              labelClassName={!isHoverBtn ? "gradient-red-text" : "text-white"}
              onHover={setHoverBtn.on}
              onLeave={setHoverBtn.off}
              to="/reports/service-ai-implementation-strategy"
              color="white"
              size="small"
              label="Tell Me More"
            />
          </div>
        </div>
      </div>
      {/*
      <div className="max-w-[1040px] lg:mt-[-160px] mx-auto px-5 lg:px-0 py-10 lg:py-20">
        <div className="space-y-4 max-w-[800px] mx-auto">
          <h3 className="text-white text-center lg:text-[32px] text-[28px] font-bold">
            Our Team Of <span className="underimage">Experts</span>
          </h3>
          <p className="text-sm text-grey-400 text-center">
            Our lessons are produced by the industries leading data engineering
            talent.
          </p>
        </div>
        <div className="flex flex-wrap gap-5 xl:gap-16 justify-center md:justify-between pt-10">
          {ourTeam.map((person, idx) => (
            <div
              key={idx}
              className="max-w-[160px] flex flex-col gap-[19px] items-center"
            >
              <img
                src={person.image}
                width={160}
                height={160}
                alt={person.name}
                loading="lazy"
              />
              <div className="flex flex-col gap-2">
                <span className="text-md font-medium text-center text-white">
                  {person.name}
                </span>
                <span className="text-center opacity-50 text-white">
                  {person.position}
                </span>
              </div>
            </div>
          ))}
        </div>
          
        
      </div>
      */}
    </DefaultLayout>
  );
};

const ourTeam = [
  {
    name: "Benjamin Wootton",
    position: "Founder",
    image: person1,
  },
  {
    name: "Rustam Musaev",
    position: "Designer",
    image: person2,
  },
  {
    name: "Ketan Moin",
    position: "Data Engineers",
    image: person3,
  },
  {
    name: "Alexander Zhukov",
    position: "Data Engineers",
    image: person4,
  },
];

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

export default About;
